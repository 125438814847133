<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 3C10.4696 3 9.96086 3.21071 9.58579 3.58579C9.21071 3.96086 9 4.46957 9 5V5.07177C7.51321 5.28635 6.12499 5.97552 5.05025 7.05025C3.7375 8.36301 3 10.1435 3 12V14C2.46957 14 1.96086 14.2107 1.58579 14.5858C1.21071 14.9609 1 15.4696 1 16V18C1 18.5304 1.21071 19.0391 1.58579 19.4142C1.96086 19.7893 2.46957 20 3 20H21C21.5304 20 22.0391 19.7893 22.4142 19.4142C22.7893 19.0391 23 18.5304 23 18V16C23 15.4696 22.7893 14.9609 22.4142 14.5858C22.0391 14.2107 21.5304 14 21 14V12C21 10.1435 20.2625 8.36301 18.9497 7.05025C17.875 5.97552 16.4868 5.28635 15 5.07177V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3H11ZM13 10C13 10.5523 13.4477 11 14 11C14.5523 11 15 10.5523 15 10V7.101C15.9534 7.29564 16.8372 7.76608 17.5355 8.46447C18.4732 9.40215 19 10.6739 19 12V14H5V12C5 10.6739 5.52678 9.40215 6.46447 8.46447C7.16285 7.76608 8.04656 7.29564 9 7.101V10C9 10.5523 9.44771 11 10 11C10.5523 11 11 10.5523 11 10V5L13 5V10ZM3 16V18L21 18V16H3Z"
    />
  </svg>
</template>
